import { Button, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { useState } from "react"

const SkippedSubmit = ({ index, comment, handleSubmit, handleSkip }) => {

    const [classification, setClassification] = useState('')
    const [label, setLabel] = useState('')

    return(
        <div>
            <Stack key={index} spacing={3}>
                <Text as="b">Select Classification :</Text>
                <RadioGroup name={`classification_${index}`} value={classification} onChange={setClassification}>
                    <Stack spacing={3} direction="row">
                        <Radio value='positive'>Positive</Radio>
                        <Radio value='sexual'>Sexual</Radio>
                        <Radio value='violent'>Violent</Radio>
                    </Stack>
                </RadioGroup>

                <Text as="b">Select Label :</Text>
                <RadioGroup name={`label_${index}`} value={label} onChange={setLabel}>
                    <Stack spacing={3} direction="row">
                        <Radio value='yes'defaultChecked>Yes</Radio>
                        <Radio value='no'>No</Radio>
                        <Radio value='maybe'>May be</Radio>
                        <Radio value='unknown'>Unknown</Radio>
                    </Stack>
                </RadioGroup>

                <div>
                    <Stack direction="row" spacing={3}>
                        <Button colorScheme='teal' size="sm" onClick={() => handleSubmit(comment.id,comment.comment, classification, label)}>Submit</Button>
                    </Stack>
                </div>

            </Stack>
        </div>
    )
}

export default SkippedSubmit;