import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const ListCommentsTable = ({ comments }) => {

    return(

        <div>
            <div>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Comment</Th>
                                <Th>Classification</Th>
                                <Th>Label</Th>
                                <Th>Answered By</Th>
                                <Th>Answered At</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            
                                {
                                    comments.map((comment, index) => (
                                        <Tr key={index}>
                                            <Td>{comment.text}</Td>
                                            <Td>{comment.classification}</Td>
                                            <Td>{comment.label}</Td>
                                            <Td>{comment.answered_by}</Td>
                                            <Td>{new Date(comment.created_at).toDateString()}</Td>                                        
                                        </Tr>
                                    ))
                                }
                                
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </div>

    )
}

export default ListCommentsTable;