import axios from 'axios';

const URL = `${process.env.REACT_APP_API_URL}`

class TextService {

    addComment(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/text/add`, payload, {
            headers: {
                authorization: token
            }
        })
    }

    listComments({ offset, limit}) {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/text/list?offset=${offset}&limit=${limit}`, {
            headers: {
                authorization: token
            }
        })
    }

    listAnsweredComments({ offset, limit}) {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/text/answered?offset=${offset}&limit=${limit}`, {
            headers: {
                authorization: token
            }
        })
    }

    listSkippedComments({ offset, limit}) {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/text/skipped?offset=${offset}&limit=${limit}`, {
            headers: {
                authorization: token
            }
        })
    }

    answerComments(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/text/answer`, payload, {
            headers: {
                authorization: token
            }
        })
    }

    skipComments(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/text/skip`, payload, {
            headers: {
                authorization: token
            }
        })
    }

    answerSkipped(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/text/answerSkipped`, payload, {
            headers: {
                authorization: token
            }
        })
    }

    uploadFile(file) {
        const token = localStorage.getItem('token')
        const formData = new FormData();
        formData.append('file',file)
        formData.append('fileName',file.name)
        return axios.post(`${URL}/text/upload`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                authorization: token
            }
        })
    }

}

export default new TextService()