import { Button, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../ApiServices/UserService";
import Answer from "../Text/Answer/Answer";
import Comments from "../Text/Comments/Comments";
import Skipped from "../Text/Skipped/Skipped";
import Text from "../Text/Text";
import Record from "./Record/Record";
import AddUser from "./AddUser/AddUser"
import './user.css'
import ChangePassword from "./ChangePassword/ChangePassword";
import UserList from "./UserList/UserList";

const User = () => {

    const navigate = useNavigate()

    const [role, setRole] = useState('')

    useEffect(() => {

        UserService.authStatus().then(result => {
            setRole(result.data.role)
            navigate('/user')
        }).catch(error => {
            navigate('/')
        })

    },[])

    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/')
    }

    return (
        <div>
            
            <div>
                <div className="header-content">
                    <div className="text-flex">
                        <h4>Text Classification</h4>
                    </div>
                    <div>  
                        <Record />
                    </div>
                    <div>
                        <ChangePassword />
                    </div>
                    <div>
                        <Button colorScheme="red" onClick={handleLogout}>Logout</Button>
                    </div>
                </div>
            </div>
            <Tabs isLazy>
                <TabList>
                    <Tab>Add Comment</Tab>
                    <Tab>Answer Comment</Tab>
                    <Tab>Answered Comments</Tab>
                    <Tab>Skipped Comments</Tab>
                    {
                        role === 1 ?
                        <Tab>Add User</Tab> :
                        ''
                    }
                    {
                        role === 1 ?
                        <Tab>Users List</Tab> :
                        ''
                    }
                </TabList>
                <TabPanels p='2rem'>
                    <TabPanel>
                        <Text />
                    </TabPanel>
                    <TabPanel>
                        <Answer />
                    </TabPanel>
                    <TabPanel>
                        <Comments />
                    </TabPanel>
                    <TabPanel>
                        <Skipped />
                    </TabPanel>
                   {
                     role === 1 ?
                     <TabPanel>
                        <AddUser />
                    </TabPanel>:
                    ''
                   }
                   {
                     role === 1 ?
                    <TabPanel>
                        <UserList />
                    </TabPanel>:
                    ''
                   }
                </TabPanels>
            </Tabs>

        </div>
    )
}

export default User;