import { Button, Heading, Input, Stack, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../../ApiServices/UserService";
import './adduser.css'

const AddUser = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const toast = useToast()
    const navigate = useNavigate()

    useEffect(() => {
        UserService.authStatus().then(result => {
            navigate('/user')
        }).catch(error => {
            navigate('/')
        })

    }, [])

    const handleSubmit = () => {
        const addUserPayload = {
            name,
            email,
            password,
            confirmPassword
        }

        UserService.addUser(addUserPayload).then(result => {

            setName('')
            setEmail('')
            setPassword('')
            setConfirmPassword('')

            return toast({
                title: "User created Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })

        }).catch(error => {

            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })
    }

    return (
        <div>

            <div className="adduser-container">

                <Stack className="adduser-stack" spacing={3}>
                    <Heading marginBottom={5} color="teal" as='h1' size='lg' noOfLines={1}>
                        Add Users
                    </Heading>
                    <Input placeholder="Name" size="md" value={name} onChange={e => setName(e.target.value)}/>
                    <Input placeholder="Email" size="md" value={email} onChange={e => setEmail(e.target.value)}/>
                    <Input placeholder="Password" type="password" size="md" value={password} onChange={e => setPassword(e.target.value)}/>
                    <Input placeholder="Confirm Password" type="password" size="md" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
                    <Button colorScheme="teal" onClick={handleSubmit}>Add User</Button>
                </Stack>

            </div>

        </div>
    )
}

export default AddUser;