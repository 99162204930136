import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import RecordService from '../../../ApiServices/RecordService'

const Record = () => {

    const [recordStatus, setRecordStatus] = useState(false)

    const { isOpen, onClose, onOpen } = useDisclosure()

    const toast = useToast()

    const getRecordStatus = () => {
        RecordService.statusRecord().then(res => {
            setRecordStatus(res.data.status)
        }).catch(error => {
            console.log(error)
        })
    }

    const handleStart = () => {

        RecordService.startRecord().then(res => {
            onClose()
            getRecordStatus()
            return toast({
                title: "Record Started",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }).catch(error => {
            onClose()
            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })

    }


    const handleStop = () => {

        RecordService.stopRecord().then(res => {
            onClose()
            getRecordStatus()
            return toast({
                title: "Record Stopped",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }).catch(error => {
            onClose()
            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })

    }

    useEffect(() => {

        getRecordStatus()

    }, [recordStatus])

    return(
        <>
            {
                recordStatus ?  
                <Button colorScheme='red' onClick={onOpen}>Stop Record</Button> :
                <Button colorScheme='teal' onClick={onOpen}>Record Chat</Button> 
            }
               
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Record Chat
                </AlertDialogHeader>

                <AlertDialogBody>
                {
                    recordStatus ?
                    'Are you sure? You want to stop chat.':
                    'Are you sure? You want to record chat.'
                }
                
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                {
                    recordStatus ? 
                    <Button colorScheme='red' onClick={handleStop} ml={3}>Stop</Button> :
                    <Button colorScheme='teal' onClick={handleStart} ml={3}>Start</Button>
                }
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
    )
}

export default Record;