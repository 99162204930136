import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserService from '../../../ApiServices/UserService'

const ChangePassword = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()
    const navigate = useNavigate()

    const[oldPassword, setOldPassword] = useState('')
    const[newPassword, setNewPassword] = useState('')
    const[confirmPassword, setConfirmPassword] = useState('')

    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/')
    }

    const handleChangePassword = () => {
        const changePasswordPayload = {
            oldPassword, 
            newPassword, 
            confirmPassword
        }

        UserService.changePassword(changePasswordPayload).then(res => {

            handleLogout()
            onClose()
            return toast({
                title: "Password Changed",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })

        }).catch(error => {

            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        })
    }

  return (
    <div>
        <Button colorScheme="teal" onClick={onOpen}>Change Password</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack spacing={3}>
                    <Input placeholder="Old Password" type="password" size="md" value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
                    <Input placeholder="New Password" type="password" size="md" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                    <Input placeholder="Confirm Password" type="password" size="md" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
                </Stack>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='teal' onClick={handleChangePassword}>Change Password</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </div>
  )
}

export default ChangePassword