import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './Components/User/Signin/Signin';
import User from './Components/User/User';

function App() {
  return (
    <ChakraProvider>
       <BrowserRouter>
        <Routes>
            <Route path='/' element={<Signin/>}/>
            <Route path='/user' element={<User/>}/>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
