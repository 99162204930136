import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import UserService from '../../../../ApiServices/UserService';

const UsersListTable = ({ users }) => {

    const toast = useToast()

    const handleResetPassword = (userId) => {
        const resetPasswordPayload = {
            userId
        }

        UserService.resetPassword(resetPasswordPayload).then(res => {

            return toast({
                title: "Reset Password Done",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })

        }).catch(error => {
            
            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })
    }
    
    
    return(

        <div>
            <div>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Reset Password</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        
                            {
                                users.map((user) => (
                                    <Tr key={user.id}>
                                        <Td>{user.name}</Td>
                                        <Td>{user.email}</Td>
                                        <Td>
                                            <Button colorScheme='red' onClick={() => handleResetPassword(user.id)}>Reset Password</Button>
                                        </Td>                                       
                                    </Tr>
                                ))
                            }
                            
                    </Tbody>
                </Table>
            </TableContainer>
            </div>
        </div>

    )
}

export default UsersListTable;