import { Button, Heading, Input, Stack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../ApiServices/UserService";
import './signin.css'

const Signin = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const toast = useToast()
    const navigate = useNavigate()

    useEffect(() => {

        UserService.authStatus().then(result => {
            navigate('/user')
        }).catch(error => {
            navigate('/')
        })
        
    }, [])

    const handleSubmit = () => {
        const signinPayload = {
            email,
            password
        }

        UserService.signin(signinPayload).then(result => {

            localStorage.setItem('token', result.data.jwtToken)
            navigate('/user')

        }).catch(error => {

            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })
    }

    return (
        <div>

            <div className="signin-container">

                <Stack className="signin-stack" spacing={3}>
                    <Heading marginBottom={5} color="teal" as='h1' size='lg' noOfLines={1}>
                        Text Classification
                    </Heading>
                    <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} size="md"/>
                    <Input placeholder="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} size="md"/>
                    <Button colorScheme="teal" onClick={handleSubmit}>Signin</Button>
                </Stack>
            </div>

        </div>
    )
}

export default Signin;