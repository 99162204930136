import axios from "axios"

const URL = `${process.env.REACT_APP_API_URL}/record`

class RecordService {
   
    startRecord() {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/start`,{},{
            headers: {
                authorization: token
            }
        })
    }

    stopRecord() {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/stop`,{},{
            headers: {
                authorization: token
            }
        })
    }

    statusRecord() {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/status`,{
            headers: {
                authorization: token
            }
        })
    }

}

export default new RecordService()