import { Box, Button, Input, Kbd, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import TextService from "../../../ApiServices/TextService";

const Upload = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [file, setFile] = useState('')

    const toast = useToast()

    const handleUpload = () => {
        TextService.uploadFile(file).then(res => {
            onClose()
            return toast({
                title: "File Uploaded",
                description:"Comments are added",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })

        }).catch(error => {
            onClose()
            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        })
    }

    return(
        <>
            <Button size='md' colorScheme="teal" onClick={onOpen}>Upload File</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Upload File</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                   <Stack spacing={3}>
                        <Text as='samp' color="red">*Important</Text>
                        <Text as='samp' fontSize="14px"><Kbd>.txt</Kbd> files are only allowed.</Text>
                        <Text as='samp' fontSize="14px">The file should be like this below.</Text>
                        <Box bg="teal.50" borderWidth='1px' borderRadius='lg' overflow='hidden' padding="10px">
                            <Stack spacing={0}>
                                <Text as='samp' fontSize="14px">Hello, How are you?</Text>
                                <Text as='samp' fontSize="14px">Iam busy</Text>
                                <Text as='samp' fontSize="14px">It is good to know</Text>
                            </Stack>
                        </Box>
                        <Text as='samp' color="red" fontSize="14px">*Each comment should be in the next line.</Text>
                        <Text as="b">Select File :</Text>
                        <Input type="file" onChange={e => setFile(e.target.files[0])}/>
                   </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="teal" onClick={handleUpload}>Upload</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Upload;