import { Select, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { 
    Pagination,
    usePagination,
    PaginationPage,
    PaginationNext,
    PaginationPrevious,
    PaginationPageGroup,
    PaginationContainer,
    PaginationSeparator,
} from '@ajna/pagination'
import UserService from "../../../ApiServices/UserService"
import UsersListTable from "./Table/UserListTable"


const UserList = () => {

    const [users, setUsers] = useState([])
    const [usersTotal, setUsersTotal] = useState()

    
    const toast = useToast()

    const outerLimit = 2
    const innerLimit = 2

    const {
        pages,
        pagesCount,
        offset,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
    } = usePagination({
        total: usersTotal,
        limits: {
            outer: outerLimit,
            inner: innerLimit,
        },
        initialState: {
            pageSize: 10,
            isDisabled: false,
            currentPage: 1,
        },
    })

   
    const listUsers = () => {
        const listUsersPayload = {
            offset,
            limit: pageSize
        }

        UserService.listUsers(listUsersPayload).then(result => {

            setUsers(result.data.listUsers)
            setUsersTotal(result.data.count)

        }).catch(error => {

            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })
    }

    useEffect(() => {

        listUsers()

    }, [currentPage, pageSize,offset])


    const handlePageChange = (nextPage) => {
        setCurrentPage(nextPage);
        console.log("request new data with ->", nextPage);
    };

    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value);
    
        setPageSize(pageSize);
    };


    return (
        <div>
            <div>
            <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                >
                    <PaginationContainer
                        align="center"
                        justify="space-between"
                        p={4}
                        w="full"
                     >
                    <PaginationPrevious
                        _hover={{
                            bg: "teal.400"
                        }}
                        bg="teal.300"
                      >Previous</PaginationPrevious>
                    <PaginationPageGroup
                    separator={
                        <PaginationSeparator
                            bg="blue.300"
                            fontSize="sm"
                            w={7}
                            jumpSize={11}
                        />
                    }>
                        {pages.map((page) => (
                        <PaginationPage 
                            key={`pagination_page_${page}`} 
                            page={page} 
                            w={7}
                            fontSize="sm"
                            _hover={{
                                bg: "rgb(167,241,233)"
                            }}
                            _current={{
                                bg: "rgb(167,241,233)",
                                fontSize: "sm",
                                w: 7
                            }}
                        />
                        ))}
                    </PaginationPageGroup>
                    <PaginationNext
                        _hover={{
                            bg: "teal.400"
                        }}
                        bg="teal.300"
                    >Next</PaginationNext>
                    </PaginationContainer>
                </Pagination>
                <Select ml={3} value={pageSize} onChange={handlePageSizeChange} w={40}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </Select>
                <UsersListTable users={users} />
            </div>
        </div>
    )
}

export default UserList;