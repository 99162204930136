import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import SkippedSubmit from './SkippedSubmit';

const SkippedTable = ({ comments, handleSubmit }) => {
    
    return(

        <div>
            <div>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Comment</Th>
                            <Th>Remark</Th>
                            <Th>Added By</Th>
                            <Th>Created At</Th>
                            <Th>Answer</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        
                            {
                                comments.map((comment, index) => (
                                    <Tr key={comment.id}>
                                        <Td>{comment.comment}</Td>
                                        <Td>{comment.remark}</Td>
                                        <Td>{comment.added_by}</Td>
                                        <Td>{new Date(comment.created_at).toDateString()}</Td>
                                        <Td>
                                            <SkippedSubmit index={index} comment={comment} handleSubmit={handleSubmit} />
                                        </Td>                                       
                                    </Tr>
                                ))
                            }
                            
                    </Tbody>
                </Table>
            </TableContainer>
            </div>
        </div>

    )
}

export default SkippedTable;