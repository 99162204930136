import axios from "axios"

const URL = `${process.env.REACT_APP_API_URL}/user`

class UserService {
    signin(payload) {
        return axios.post(`${URL}/signin`,payload)
    }

    addUser(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/add`,payload, {
            headers: {
                authorization: token
            }
        })
    }

    changePassword(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/changePassword`,payload, {
            headers: {
                authorization: token
            }
        })
    }

    resetPassword(payload) {
        const token = localStorage.getItem('token')
        return axios.post(`${URL}/resetPassword`,payload, {
            headers: {
                authorization: token
            }
        })
    }

    listUsers({ offset, limit }) {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/list?offset=${offset}&limit=${limit}`, {
            headers: {
                authorization: token
            }
        })
    }

    authStatus() {
        const token = localStorage.getItem('token')
        return axios.get(`${URL}/auth`,{
            headers: {
                authorization: token
            }
        })
    }
}

export default new UserService()