import './text.css'
import { Button, Heading, Input, Stack, Textarea, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import TextService from '../../ApiServices/TextService'
import Upload from './Upload/Upload'

const Text = () => {

    const [comment, setComment] = useState('')
    const [remark, setRemark] = useState('')

    const toast = useToast()


    useEffect(() => {


    }, [])

    const handleSubmit = () => {
        const addTextPayload = {
            comment,
            remark
        }

        TextService.addComment(addTextPayload).then(result => {
            setComment('')
            setRemark('')
            return toast({
                title: "Added Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })

        }).catch(error => {
            setComment('')
            setRemark('')
            return toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

        })

    }

    return(
        <div>
        <div className='text-container'>
            <Stack className="text-stack" spacing={3}>
                <Heading marginBottom={5} color="teal" as='h1' size='lg' noOfLines={1}>
                        Add Comments
                </Heading>
                <Textarea placeholder='Type the Text' required={true} value={comment} onChange={e => setComment(e.target.value)} size='md' height={40}/>
                <Input placeholder='Remark(Optional)' value={remark} onChange={e => setRemark(e.target.value)} />
                <Button colorScheme='teal' size='md' onClick={handleSubmit}>
                    Submit
                </Button>
                <Upload />
            </Stack>
        </div>
    </div>
    )
}

export default Text;